
form[name=module-switch-form] {
  display: flex;
}
form[name=module-switch-form] > div {
  display: flex;
  margin-bottom: 0;
  flex-grow:1;
}
form[name=module-switch-form] > div > label {
  margin-right:10px;
  line-height: 36px;
  margin-bottom: 0px;
}
form[name=module-switch-form] > div > div {
  flex-grow:1;
}
form[name=module-switch-form] > button {
  margin-left:10px;
}


table.missingVATNumbers table {
  margin: 0;
  padding: 0;
  width: 100%;
}
table.missingVATNumbers td:has(> table) {
  padding:0;
}
table.missingVATNumbers table th {
  display:none;
}


.report table {
  width: auto;
}


.generate-success .fa[data-v-75127732] {
  font-size: 1.3em;
}

